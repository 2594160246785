"use client";

import React, { use } from "react";
// import { useCurrentLocale } from "next-i18n-router/client";

import ErrorPageUI from "@/components/ErrorPageUI/ErrorPageUI";
// import i18nConfig from "../../../i18nConfig";

import { IServerErrorPageResponse } from "@/interfaces/ErrorPage/ErrorPage";
import Typography from "@/components/Typography/Typography";
// import { Config } from "next-i18n-router/dist/types";

/* 
Custom queryClient to fix the infinite loop issue when using the "use" hook 
or when fetching inside a useEffect hook 
*/
const fetchMap = new Map<string, Promise<IServerErrorPageResponse>>();
function queryClient(name: string, query: () => Promise<IServerErrorPageResponse>) {
  if (!fetchMap.has(name)) {
    fetchMap.set(name, query());
  }
  return fetchMap.get(name)!;
}

export default function ErrorPage() {
  // const currentLocale = useCurrentLocale(i18nConfig as Config);
  const { serverErrorPage }: IServerErrorPageResponse = use(
    queryClient("error", () =>
      fetch(`/api/error-page`).then((res) => {
        return res.json();
      }),
    ),
  );

  return serverErrorPage ? (
    <ErrorPageUI data={serverErrorPage} />
  ) : (
    <div>
      <Typography variant="hero-subtitle" className="text-center">
        Oops, Something went wrong...
      </Typography>
    </div>
  );
}
